





















import { Component, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class EficiencyComponent extends Vue {
  data() {
    return {
      eficiencyImages: [
        "images/resultado1.jpg",
        "images/resultado2.jpg",
        "images/resultado3.jpg",
        "images/resultado4.jpg",
        "images/resultado5.jpg",
        "images/resultado6.jpg",
      ],
    };
  }
}
