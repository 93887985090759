





















import { Component, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class BenefitsComponent extends Vue {
  data() {
    return {
      benefits: [
        {
          image: "images/icon5-1-1024x1024.png",
          title: "Elimina gordura localizada",
          description: `Por conter ingredientes com alto teor de água, fibras e nutrientes,
              Natudrin ajuda a liberar as toxinas e os resíduos presos no trato digestivo.
              Evita também o inchaço abdominal, indisposição e retenção de líquidos,
              auxiliando para o emagrecimento rápido e saudável.`,
        },
        {
          image: "images/icon1-1-1024x1024.png",
          title: "Inibe o Apetite!",
          description: `Os Ativos de Natudrin, ao serem ingeridos, agem criando uma camada de “gel” no estômago que aumenta a sensação de saciedade. Excelente para quem está sempre com fome e acaba comendo mais do que deveria.`,
        },
        {
          image: "images/icon2-1-1024x1024.png",
          title: "Acelera o Metabolismo!",
          description: `Natudrin é uma ótima fonte de energia para o corpo. Pois ele acelera o metabolismo de maneira natural, assim, o organismo queima até 4x mais calorias no dia a dia. É simples, se você queima mais calorias do que você ingere você EMAGRECE!.`,
        },
        {
          image: "images/icon3-1-1024x1024.png",
          title: "Elimina a Flacidez!",
          description: `Natudrin tem colágeno em sua composição, desempenhando funções importantes, como: ajuda a dar sustentação a pele, contribuindo para eliminar a flacidez. Rugas e marcas de expressão também são diminuídas, deixando sua pele com aparência iluminada e jovem.`,
        },
        {
          image: "images/icon4-1-1024x1024.png",
          title: "Queima de gordura!",
          description: `Natudrin ajuda atuando como uma fibra capaz de contribuir na eliminação de gordura, além de auxiliar na digestão e absorção das mesmas pelo trato intestinal, ajudando a facilitar a excreção de todas as gordurinhas.`,
        },
        {
          image: "images/icone0-1024x1024.png",
          title: "Combate o colesterol ruim!",
          description: `Natudrin é um produto 100% natural e todos os seus ingredientes juntos promovem um efeito detox no organismo. Esse efeito detox é capaz de quebrar as moléculas de colesterol ruim do seu corpo.`,
        },
      ],
    };
  }
}
