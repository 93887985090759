























import { Component, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class ApresentationComponent extends Vue {}
