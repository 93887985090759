









import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class NoticeComponent extends Vue {
  @Prop() private title!: string;
  @Prop() private description!: string;
}
