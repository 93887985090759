
























import { Component, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class MediaComponent extends Vue {
  data() {
    return {
      mediaImages: [
        {
          image: "images/midia-boaforma-1024x1024.png",
          description: `A Fibra Psyllium é capaz de fazer emagrecer e limpar o intestino, além de garantir a sensação de saciedade, a semente ajuda a limpar as paredes do intestino, ajudando a eliminar as toxinas e impurezas.”`,
        },
        {
          image: "images/midia-hojeemdia-1024x1024.png",
          description: `“O ágar-ágar atua como estimulante na produção de colágeno pelo organismo, melhorando a elasticidade da pele e fortificação dos cabelos e unhas. Ele também pode auxiliar no controle do peso, estimulando a saciedade.”`,
        },
        {
          image: "images/midia-r7-1024x1024.png",
          description: `“Natudrin virou febre nos Estados Unidos por causa do seu efeito na queima de gorduras. Em um estudo realizado por lá, ficou constatado que, o produto pode dobrar o número de quilos perdidos durante uma dieta.”`,
        },
        {
          image: "images/midia-maisvoce-1024x1024.png",
          description: `“O Natudrin é rica em fibras, que aumentam de tamanho no estômago, trazendo uma sensação de preenchimento, o que faz com que a pessoa se sinta satisfeita com menos comida e saciada por muito mais tempo.”`,
        },
      ],
    };
  }
}
