import { render, staticRenderFns } from "./TestimonyComponent.vue?vue&type=template&id=b5a66718&scoped=true&"
import script from "./TestimonyComponent.vue?vue&type=script&lang=ts&"
export * from "./TestimonyComponent.vue?vue&type=script&lang=ts&"
import style0 from "./TestimonyComponent.vue?vue&type=style&index=0&id=b5a66718&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5a66718",
  null
  
)

export default component.exports