

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BuyComponent extends Vue {
  @Prop() small!: boolean;
  @Prop() darkImage!: boolean;
  goToBuySection() {
    window.scrollTo(0, (document as any).getElementById('buy').offsetTop - 60);
  }
}
