

























































import { Component, Vue, Watch } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
import NoticeComponent from "./NoticeComponent.vue";
import ApresentationComponent from "./ApresentationComponent.vue";
import SidebarComponent from "./SidebarComponent.vue";
import ProductComponent from "./ProductComponent.vue";
import MediaComponent from "./MediaComponent.vue";
import BenefitsComponent from "./BenefitsComponent.vue";
import TestimonyComponent from "./TestimonyComponent.vue";
import WhatsPrintComponent from "./WhatsPrintComponent.vue";
import EficiencyComponent from "./EficiencyComponent.vue";
import FormulaComponent from "./FormulaComponent.vue";
import PromiseComponent from "./PromiseComponent.vue";
import EbookComponent from "./EbookComponent.vue";
import FaqComponent from "./FaqComponent.vue";
import BuyProductComponent from "./BuyProductComponent.vue";
import SecurityComponent from "./SecurityComponent.vue";
import NoPiracyComponent from "./NoPiracyComponent.vue";
import FooterComponent from "./FooterComponent.vue";

@Component({
  components: {
    BuyComponent,
    NoticeComponent,
    ApresentationComponent,
    SidebarComponent,
    ProductComponent,
    MediaComponent,
    BenefitsComponent,
    TestimonyComponent,
    WhatsPrintComponent,
    FaqComponent,
    EficiencyComponent,
    FormulaComponent,
    PromiseComponent,
    EbookComponent,
    BuyProductComponent,
    SecurityComponent,
    NoPiracyComponent,
    FooterComponent,
  },
})
export default class Home extends Vue {
  benefits: any[] = [];
  goToSection = null;
  isSidebarOpen = false;
  showNavbar = false;

  @Watch("goToSection")
  scrollTo(): void {
    if (!this.goToSection) {
      return;
    }
    this.isSidebarOpen = false;
    window.scrollTo(0, (document as any).getElementById(this.goToSection).offsetTop - 60);
    this.goToSection = null;
  }

  handleScroll() {
    this.showNavbar = window.scrollY > 250;
  }
  handleSidebarClick() {
    this.$emit("clickFromChildComponent");
  }
  navigateToSection(value: any) {
    this.goToSection = value;
  }

  public toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  mounted() {
    document.addEventListener("contextmenu", function (e: any) {
      e.preventDefault();
    });
    document.onkeydown = function (e: any) {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };
  }

  created() {
    window.addEventListener("scroll", this.handleScroll);
  }
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
