
















import { Component, Prop, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class SidebarComponent extends Vue {
  @Prop() isToggle = false;

  items: { label: string; href: string }[] = [
    { label: "Apresentação", href: "apresentation" },
    { label: "Veja como Natudrin age no organismo!", href: "apresentation" },
    { label: "O que a mídia diz", href: "media" },
    { label: "Benefícios do Natudrin", href: "benefits" },
    { label: "Depoimentos de clientes", href: "testimony" },
    { label: "Eficiência do Natudrin", href: "eficiency" },
    { label: "Fórmula do Natudrin", href: "formula" },
    { label: "Promoção do ebook", href: "ebook" },
    { label: "Ofertas do Natudrin", href: "buy" },
    { label: "Segurança na Compra", href: "security" },
    { label: "FAQ - Perguntas Frequentes", href: "faq" },
  ];

  goToSection(section: string) {
    this.$emit("selectSection", section);
  }
}
