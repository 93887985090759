


































































import { Component, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class BuyProductComponent extends Vue {
  data() {
    return {
      products: [
        {
          offer: "O MAIS ECONÔMICO",
          percentOff: "41%",
          price: "1361,40",
          splitPrice: "44,97",
          fullPrice: "447,90",
          image: "images/6frascos.png",
          linkBuy: "https://ev.braip.com/checkout/pla51411/chey6w93?split=12",
        },
        {
          offer: "O MAIS VENDIDO",
          percentOff: "62%",
          price: "591,00",
          splitPrice: "34,93",
          fullPrice: "347,90",
          image: "images/3frascos.png",
          linkBuy: "https://ev.braip.com/checkout/pla7ren3/chey6w93?split=12",
        },
        {
          offer: "EXPERIMENTE",
          percentOff: "20%",
          price: "247,90",
          splitPrice: "19,87",
          fullPrice: "197,90",
          image: "images/1frasco.png",
          linkBuy: "https://ev.braip.com/checkout/pla7y2p5/chey6w93?split=12",
        },
      ],
    };
  }
}
