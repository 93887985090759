




















import { Component, Vue } from "vue-property-decorator";
import BuyComponent from "./BuyComponent.vue";
@Component({
  components: {
    BuyComponent,
  },
})
export default class WhatsPrintComponent extends Vue {
  data() {
    return {
      whatsappImages: [
        "images/natudrin-depoimento-2.jpg",
        "images/natudrin-depoimento-1.jpg",
        "images/natudrin-depoimento-3.jpg",
        "images/natudrin-depoimento-4.jpg",
        "images/natudrin-depoimento-5.jpg",
        "images/natudrin-depoimento-6.jpg",
      ],
    };
  }
}
