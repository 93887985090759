import { render, staticRenderFns } from "./BenefitsComponent.vue?vue&type=template&id=b037b0de&scoped=true&"
import script from "./BenefitsComponent.vue?vue&type=script&lang=ts&"
export * from "./BenefitsComponent.vue?vue&type=script&lang=ts&"
import style0 from "./BenefitsComponent.vue?vue&type=style&index=0&id=b037b0de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b037b0de",
  null
  
)

export default component.exports